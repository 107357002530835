import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Children, useState, useRef, useEffect } from "react";

export default function MyGridItem({headertext, children}) 
{
//  const childFunc = useRef(null);
  //const [clickEvent, setClickEvent] = useState(null);
  /*useEffect(() => {
    const arrayChildren = Children.toArray(children);
    let c = arrayChildren[0]
  })*/
/*  const arrayChildren = Children.toArray(children);
  arrayChildren[0].

  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };
*/
  function testClick()
  {
    /*if (children.testval)
    {
      alert("Child directly: testval=" + children.testval);
      return;
    }
    if (children.onParentClick)
    {
      alert("Child directly got parentclick function");
      return;
    }
    const arrayChildren = Children.toArray(children);
    let child = arrayChildren[0];
    console.log("child props=" + JSON.stringify(child, getCircularReplacer()));
    if (!child) alert("Child is null");
    else {
      if (child.testval) alert("Child testval=" + child.testval);
      else if (child.props.testval) alert("Child testval=" + child.props.testval);
      else if (child.onParentClick) alert("Child onParentClick found");
      else alert("Child found, prop not!");
    }*/
    const arrayChildren = Children.toArray(children);
    let child = arrayChildren[0];
    if (child.props.onClickFunc)
    {
      //alert("onclickfunc defined");
      child.props.onClickFunc.current();
    } 
    //alert("testval = " + child.props.testval);
  }

  //let child = arrayChildren[0];
/*  const [data, setData] = useState('
');
  const parentToChild = () => {
    setData("Testing");
  }*/
  return (
       <Grid item xs={4}>
         <Paper elevation={3}>
           <AppBar position="static">
             <Toolbar variant="dense" disablegutters='true' onClick={() => {testClick();}}>
               <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>{headertext}</Typography>
             </Toolbar>
           </AppBar>
           {children}
         </Paper>
       </Grid>
  )
}
