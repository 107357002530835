import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React, { useState, useRef, useEffect } from 'react';

export default function TrashPlan({ onClickFunc }) {
/*  const trash = [{"name":"Restavfall", startWeek:1, interval: 2},
                 {"name":"Papir", startWeek:4, interval: 4},
                 {"name":"Plast", startWeek:6, interval: 8}];*/
  const trash = [{"name":"Rest", startWeek:2, interval: 4},
                 {"name":"Papir", startWeek:4, interval: 4},
                 {"name":"Mat", startWeek:1, interval: 2},
                 {"name":"Glass/Metall", startWeek:11, interval: 12},
                 {"name":"Plast (torsdag)", startWeek:1, interval: 6}];


  const [hour, setHour] = useState(null);  // Forces a re-render every hour
  const hourRef = useRef(hour);
  hourRef.current = hour;

  const [testText, setTestText] = useState("unset");  // Forces a re-render every hour
  const testTextRef = useRef(testText);
  testTextRef.current = testText;

  React.useEffect(() => {
    if (onClickFunc)
      onClickFunc.current = onParentClick;
  }, [])

  function onParentClick()
  {

    //setTestText("parent clicked me");
  }

  useEffect(() => {
    const interval = setInterval(() => {
      let currentHour = (new Date()).getHours();
      if (currentHour != hourRef.current)
      {
        setHour(currentHour);
      }
    }, 5*60*1000);
    return () => clearInterval(interval);
  }, []);

  function weekNumber(date = new Date())
  {
    var firstJanuary = new Date(date.getFullYear(), 0, 1);
    var dayNr = Math.ceil((date - firstJanuary) / (24 * 60 * 60 * 1000));
    var weekNr = Math.ceil((dayNr + firstJanuary.getDay()) / 7);
    return weekNr;
  }

  let current = "";
  let next = "";
  let currentWeekNumber = weekNumber();
  let nextWeekNumber = currentWeekNumber + 1;

  for (let i = 0; i<trash.length; i++)
  {
    let startWeek = trash[i].startWeek;
    let interval = trash[i].interval;
    if ((currentWeekNumber - startWeek) % interval == 0)
    {
      if (current !== "") current += " og ";
      current += trash[i].name;
    }
    if ((nextWeekNumber - startWeek) % interval == 0)
    {
      if (next !== "") next += " og ";
      next += trash[i].name;
    }
  }
  if (current == "") current = "-";
  if (next == "") next = "-";

  console.log("trashplan rendering");
  return (
    <List
      sx={{ width: '100%', maxWidth: 360 }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItem sx={{py:0}}>
        <ListItemText style={{whiteSpace: 'pre-line'}} sx={{my:0.25}} primary={`Denne uka: ${current}`} />
      </ListItem>
      <ListItem sx={{py:0}}>
        <ListItemText style={{whiteSpace: 'pre-line'}} sx={{my:0.25}} primary={`Neste uke: ${next}`} />
      </ListItem>
    </List>
  );
//  return (<div>Denne uka: {current}<br/>Neste uke: {next}</div>);
}
