
//import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import Canvas from './components/canvas';
import React, { useState, useRef, useEffect } from 'react';

/*
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
*/

export default function SpotPrices({ onClickFunc }) {
  const [prices, setPrices] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [updated, setUpdated] = useState(null);
  const [hour, setHour] = useState(null);  // Forces a re-render every hour
  
  const pricesRef = useRef(prices);

  pricesRef.current = prices;
  const hourRef = useRef(hour);
  hourRef.current = hour;

  const [showHours, setShowHours] = useState(4);
  const showHoursRef = useRef(showHours);
  showHoursRef.current = showHours;
  
  useEffect(() => {
    if (onClickFunc) onClickFunc.current = onParentClick;
  }, [])

  function onParentClick()
  {
    //alert("On parent click - spotprices");
    if (showHoursRef.current < 5)
    {
      setShowHours(30);
    }
    else
    {
      setShowHours(4);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      // Runs every minute
      //console.log('Interval loop!');
//      if (!isLoaded) return;
//      if (prices.length )
      let numPrices = pricesRef.current.length;
      //console.log("Num prices = " + numPrices);
      if (numPrices <= 11)
      {
        console.log("Trying to update prices, length = " + numPrices);
        fetchPrices();
      }
      let currentHour = (new Date()).getHours();
    
      if (currentHour != hourRef.current)
      {
        setHour(currentHour);
      }
//      if (hour != d.getHours())
      //setHour(d.getHours());
    }, 1*60*1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchPrices();
  }, [])
  function fetchPrices()
  {
    console.log("FETCHING PRICES...");
    fetch("https://api.hansbakk.com/power/spotprice/?action=getprices", {
        method: 'GET',  
        withCredentials: true,  
        crossorigin: true,  
        //mode: 'no-cors',     
      })
      .then(res => res.json())
      //.then(response => response.text())
      .then(
        (result) => {
          //console.log("Result = " + result);
          const d = new Date();
          setIsLoaded(true);
          setPrices(result);
          setUpdated("" + d.getHours() + ":" + d.getMinutes());
          //setError({"message:":result});
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }
//  const [open, setOpen] = React.useState(true);

/*  const handleClick = () => {
    setOpen(!open);
  };*/
  // , bgcolor: 'background.paper'
//  <ListItemText primary={`${spotprice.price.toFixed(2)}`} />
/*
            <ListItem disablePadding>

*/
  console.log('Spotprices rendering!');
  if (!isLoaded)
  {
    return <div>Loading...</div>;
  }
  else if (error)
  {
    return <div>Error: {error.message}</div>;
  }
  else
  {
    let hoursShown = 0;
    return (
      <List
        sx={{ width: '100%', maxWidth: 360 }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {prices.map(spotprice => {
          if (hoursShown < showHours)
          {
            //let divider = spotprice.hour == 23 ? "<Divider/": "";
            var timestamp = spotprice.timestamp;
            var a = new Date(timestamp*1000);
            let d = new Date();
            d.setMinutes(0);
            d.setSeconds(0);
            let currentHourTimestamp = Math.floor((d.getTime()/1000));
            if (timestamp >= currentHourTimestamp)
            {
              hoursShown++;
              var days = ['Søndag','Mandag','Tirsdag','Onsdag','Torsdag','Fredag','Lørdag'];
              var dayOfWeek = days[a.getDay()];
              let priceSubsidized = spotprice.price - spotprice.subsidy;
              //let label = "" + spotprice.price + "/" + priceSubdidized;
              return (
                <ListItem sx={{py:0}}>
                  <ListItemText sx={{my:0.25}} primary={`${dayOfWeek} kl ${("0" + spotprice.hour).slice(-2)}`} />
                  <Canvas price={spotprice.price} priceSubsidized={priceSubsidized}/>
                </ListItem>
              )
            }
          }
        })}
      </List>
      );
  }
}
