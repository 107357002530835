import logo from './logo.svg';
import './App.css';
import SpotPrices from './spotprices';
import TrashPlan from './trashplan';
import FamCal from './famcal';
import AppBar from '@mui/material/AppBar';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid';
import MyGridItem from './components/mygriditem';

function App() {

/*  return (<div>
        <SpotPrices/>
      </div>
    )*/
    /*
      <AppBar position="static">
        <Toolbar variant="dense">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Strømpriser m.m...
          </Typography>
          <Button color="inherit">Login</Button>
        </Toolbar>
      </AppBar>


                <Grid item xs={4}>
            <Paper elevation={3}><AppBar position="static"><Toolbar variant="dense" disablegutters='true'><Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Strømpriser</Typography></Toolbar></AppBar><SpotPrices/></Paper>
          </Grid>

    */
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
          <MyGridItem item xs={4} headertext='Strømpriser'>
            <SpotPrices onClickFunc={() => {}} />
          </MyGridItem>
          <MyGridItem item xs={4} headertext='Familiekalender' >
            <FamCal onClickFunc={() => {}}/>
          </MyGridItem>
          <MyGridItem item xs={4} headertext='Tømmeplan'>
             <TrashPlan testval='123' onClickFunc={() => {}}/>
          </MyGridItem>
        </Grid>
      </Box>
    </Box>
  );
}

export default App;
