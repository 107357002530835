
import React, { useState, useRef, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

export default function FamCal({ onClickFunc }) {
  const [dayEvents, setDayEvents] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  const [showDays, setShowDays] = useState(1);
  const showDaysRef = useRef(showDays);
  showDaysRef.current = showDays;

  
  useEffect(() => {
    if (onClickFunc) onClickFunc.current = onParentClick;
  }, [])

  function onParentClick()
  {
    //alert("On parent click - spotprices");
    if (showDaysRef.current < 2)
    {
      setShowDays(7);
      fetchCalendar(7);
    }
    else
    {
      setShowDays(1);
      fetchCalendar(1);
    }
  }
  
  function getWeekDay(timestamp)
  {
    let dt = new Date(timestamp*1000);
    const days = ['Søndag','Mandag','Tirsdag','Onsdag','Torsdag','Fredag','Lørdag'];
    return days[dt.getDay()]
  }

  function fetchCalendar(overrideDays)
  {
    let days = overrideDays || showDaysRef.current;
    //console.log("showDays = " + showDays + ", ref = " + showDaysRef.current);
    let timestamp = Math.floor(Date.now()/1000);
    let to_timestamp = timestamp;
    if (days > 1)
    {
      to_timestamp += ((days-1) * (24*60*60));
    }
    fetch("https://api.hansbakk.com/fam/familycalender/?action=getday&timestamp=" + timestamp + "&totimestamp=" + to_timestamp, {
      method: 'GET',
      withCredentials: true,  
      crossorigin: true,  
    })
    .then(res => res.json())
    .then(
      (result) => {
        setIsLoaded(true);
        setDayEvents(result);
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    )
  }
  useEffect(() => {
    const interval = setInterval(() => {
      // Runs every 5 minutes
      fetchCalendar();
    }, 5*60*1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchCalendar();
  }, [])
//  console.log("FamCal rendering");
  if (!isLoaded)
  {
    return <div>Loading...</div>;
  }
  else if (error)
  {
    return <div>Error: {error.message}</div>;
  }
  else
  {
    console.log("showDays = " + showDays);
    let currentDay = "";
    
   /* var listElement = (<List
           sx={{ width: '100%', maxWidth: 360 }}
           component="nav"
           aria-labelledby="nested-list-subheader"
          >);
*/
    return (
      <List
        sx={{ width: '100%', maxWidth: 360 }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {dayEvents.map(usertodos => {
          let user = usertodos.name;
          let todos = usertodos.todos;
          let timestamp = usertodos.day;
          //todos = todos.replace(/(?:\r\n|\r|\n)/g, '<br />');
          //let divider = spotprice.hour == 23 ? "<Divider/": "";
          //var timestamp = spotprice.timestamp;
          //var a = new Date(timestamp*1000);
          //var days = ['Søndag','Mandag','Tirsdag','Onsdag','Torsdag','Fredag','Lørdag'];
          //var dayOfWeek = days[a.getDay()];
          let iconName = process.env.PUBLIC_URL + "/images/avatars/" + user.toLowerCase().replace(" ", "_") + ".png";

          let item = (
            <ListItem sx={{py:0}}>
              <ListItemAvatar>
                <Avatar alt="Remy Sharp" src={iconName} />
              </ListItemAvatar>
              <ListItemText secondaryTypographyProps={{display:'block'}} style={{whiteSpace: 'pre-line'}} sx={{my:0.25}} secondary={`${todos}`}  />
            </ListItem>
          );

          /*          
          let item = (
              <ListItem sx={{py:0}}>
                <ListItemText secondaryTypographyProps={{display:'block'}} style={{whiteSpace: 'pre-line'}} sx={{my:0.25}} primary={`${user}`} secondary={`${todos}`}  />
              </ListItem>);*/


          if (showDays > 1)
          {
            let day = getWeekDay(timestamp);
            if (currentDay != day)
            {
              currentDay = day;
             // day = timestamp;
              return (
                <React.Fragment>
                   <ListItem sx={{py:0}}>
                     <ListItemText secondaryTypographyProps={{display:'block'}} primaryTypographyProps={{ sx: { color: "green", fontWeight: "bold" } }} style={{whiteSpace: 'pre-line'}} sx={{my:0.25}} primary={`${day}`} />
                   </ListItem>
                  {item}
                </React.Fragment>
              );
            }
            else
            {
              return <React.Fragment>{item}</React.Fragment>;
            }

          }
          else
          {
            return <React.Fragment>{item}</React.Fragment>;
          }
        })}
      </List>      
    );
  }
}
