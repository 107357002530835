
import React, { useRef, useEffect } from 'react'

const Canvas = props => {
  
  const canvasRef = useRef(null)
  const width = 200;
  const height = 24;

  const draw = ctx => {
    let maxprice = 5;
    let multiplier = (width / maxprice);
    let len = Math.min(width, Math.round(Number(props.price) * multiplier));

    // 0-100 (0=0, 20=1, 100=5)
    // Create gradient
    let grd = ctx.createLinearGradient(0, 0, width, 0);
    grd.addColorStop(0, "#0C0");
    grd.addColorStop(0.1, "#0C0");
    grd.addColorStop(0.3, "#CC0");
    grd.addColorStop(0.6, "#E00");
    grd.addColorStop(1, "#F00");

    // Fill with gradient
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, len, height);

    ctx.font = "14px Arial";
    ctx.fillStyle = "black";
    ctx.fillText("" + props.price.toFixed(2) + " (" + props.priceSubsidized.toFixed(2) + ")", 50, (height/2)+5);
//    ctx.fillStyle = '#000000'
//    ctx.beginPath()
//    ctx.arc(50, 100, 20, 0, 2*Math.PI)
//    ctx.fill()
  }
  
  useEffect(() => {
    
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    
    //Our draw come here
    draw(context)
  }, [draw])
  
  return <canvas width={width} height={height} ref={canvasRef} {...props}/>
}

export default Canvas
